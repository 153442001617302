import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MadDog2 from "../../components/MadDog2"

const Maddog = () => (
  <Layout>
    <SEO title="HaroldRichter.art" />
    <MadDog2/>
  </Layout>
)

export default Maddog
